const baseStyles = {
  root: {
    flexGrow: 1,
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: 1,
    marginRight: 1,
    width: "100%",
  },
  tagsField: {
    marginLeft: 1,
    marginRight: 1,
    width: "100%",
  },
  dividerSpacing: {
    marginTop: 5,
    marginBottom: 20,
  },
  boldFont: {
    fontWeight: 700,
  },
  whiteButton: {
    color: "#fff",
  },
  metaLogoAnimation: {
    maxWidth: 200,
    width: "80%",
  },
};

export default baseStyles;
