import React from 'react';
import { makeStyles } from '@mui/styles';

import MetaStudiosGreenM from '../../images/meta_studios_green_m_animate';

import Grid from '@mui/material/Grid';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    '& > * + *': {
      marginLeft: 16,
    },
  },
}));

export default function LoadingBlock() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container direction='row' justifyContent='center' alignItems='center' item xs={12}>
        <Grid item xs={1} align='center'>
          <MetaStudiosGreenM className={classes.metaLogoAnimation} />
        </Grid>
      </Grid>
    </div>
  );
}
