/*****************************************************************************/
/*  File Name: header.js                                                     */
/*  Date:      Nov 25, 2021                                                  */
/*  Author:    Christopher Cressman                                          */
/*  Purpose:   This is the header block that will be on the top of the pages */
/*                                                                           */
/*****************************************************************************/

import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import React from "react";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import styles from "./styles";
import { useColorMode } from "../../context/themeContext";
import { useTheme } from "@mui/material/styles";
import { useUserContext } from "../../context/userContext";
import { withTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  ...styles,
}));

function Header({ t }) {
  //Load all of the default component variables
  const theme = useTheme();
  const { user, logoutUser } = useUserContext();
  const classes = useStyles();
  const { toggleColorMode } = useColorMode();

  const returnToSite = () => {
    window.location = "https://withyou.net";
  };

  return (
    <Grid item xs container alignItems="center" justify="center" className={classes.header}>
      <Grid item xs={12} container spacing={3}>
        <Grid item xs={6}>
          <Typography variant="h6">WithYou Pathway Editor</Typography>
          <Typography style={{ fontSize: 10 }}>v1A.1</Typography>
        </Grid>
        <Grid item xs={6} justifyContent="flex-end" container spacing={2} alignItems="center">
          <Grid item>
            <Typography variant="body1">{user ? `Welcome Back - ${user.name}` : ""}</Typography>
          </Grid>
          {user ? (
            <Grid item>
              <Typography variant="body1">|</Typography>
            </Grid>
          ) : null}

          <Grid item>
            <Button className={classes.whiteButton} onClick={() => returnToSite()}>
              Return to WithYou.net
            </Button>
          </Grid>
          <Grid item>
            <IconButton onClick={toggleColorMode}>
              {theme.palette.mode === "light" ? <Brightness4Icon /> : <Brightness7Icon />}
            </IconButton>
          </Grid>
          <Grid item>
            {user ? (
              <Button className={classes.whiteButton} onClick={() => logoutUser()}>
                Sign Out
              </Button>
            ) : (
              <Button className={classes.whiteButton}>Sign In</Button>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default withTranslation()(Header);
