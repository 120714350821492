import rootSettings from '../../Styles';
import { purple } from '@mui/material/colors';

const styles = {
  ...rootSettings,
  header: {
    height: 60,
    background: '#004968',
    width: '100%',
    margin: 0,
    color: '#fff',
    paddingLeft: 40,
    paddingRight: 40,
    marginBottom: 60,
  },
};

export default styles;
