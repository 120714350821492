import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import * as ROUTES from '../../constants/routes.js';
import ErrorBoundary from '../Errors';
import Alerts from '../Alerts';
//import SystemAlerts from '../Alerts';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Header from '../Header';

const HomePage = lazy(() => import('../Dashboard'));
const LoginPage = lazy(() => import('../SignIn'));
const SignUpFormBase = lazy(() => import('../SignUp'));
const LoadingBlock = lazy(() => import('../Loading'));

const App = () => (
  <div className='app'>
    <Router>
      <CssBaseline />
      <Header />
      <Container maxWidth='xl'>
        <div className='app-page-content'>
          <Suspense fallback={<LoadingBlock />}>
            <ErrorBoundary>
              <Routes>
                <Route exact path={ROUTES.LANDING} element={<HomePage />} />
                <Route exact path={ROUTES.SIGN_UP} element={<SignUpFormBase />} />
                <Route exact path={ROUTES.LOGIN} element={<LoginPage />} />
              </Routes>
            </ErrorBoundary>
          </Suspense>
        </div>

        {/* <Footer /> */}
        <Alerts />
      </Container>
    </Router>
  </div>
);

export default App;
